<template>
  <Layout>
    <page-layout page-titles="内部メンバー管理">
      <template #table-content>
        <c-m-table
          :show-add-dialog="showAddDialog"
          :show-edit-dialog="showEditDialog"
          :show-delete-dialog="showDeleteDialog"
        >
          <template #dialog>
            <c-modal :id="dialogId" :title="dialogTitle" :buttons="dialogButtons">
              <c-m-form
                :form-data.sync="formData"
                :trigger-validate="triggerValidate"
                :is-valid.sync="isValid"
              />
            </c-modal>
            <delete-confirmation :id="dialogDeleteId" :confirm-delete-func="onDeleteConfirm" />
            <change-password-dialog
              :id="dialogChangePasswordId"
              :confirm-change-password="onChangePassword"
              :require-old-password="false"
            >
            </change-password-dialog
          ></template>
        </c-m-table>
      </template>
    </page-layout>
  </Layout>
</template>

<script>
import Layout from '@/layouts/main/app'
import CMTable from '@/components/tables/internal-member-management-table'
import PageLayout from '@/components/commons/common-page-layout'
import CModal from '@/components/commons/common-modal'
import CMForm from '@/components/forms/internal-memember-management-form'
import DeleteConfirmation from '@/components/modals/delete-confirmation-modal'
import ChangePasswordDialog from '@/components/modals/change-password-modal'
import { internalMemberManagementService } from '@/services/internal-member-management-service'
import { ALERT_TYPE } from '@/utils/constants'
import pick from 'lodash-es/pick'
import { mapActions } from 'vuex'
import { MESSAGES } from '@/utils/messages'
import { userService } from '@/services/auth-service/user-service'

export default {
  name: 'InternalMemberManagement',
  components: {
    Layout,
    CMTable,
    PageLayout,
    CModal,
    CMForm,
    DeleteConfirmation,
    ChangePasswordDialog,
  },
  data() {
    return {
      alertType: ALERT_TYPE,
      searchInput: '',
      formData: {},
      dialogId: 'imm-dialog',
      dialogDeleteId: 'del-dialog',
      dialogChangePasswordId: 'change-password-id',
      dialogTitle: '',
      dialogTitles: {
        ADD: {
          id: 1,
          text: '内部メンバー新規登録',
        },
        EDIT: {
          id: 2,
          text: '内部メンバー情報編集',
        },
        DELETE: {
          id: 3,
          text: '',
        },
      },
      onAddOrEditConfirm: () => {
        return {}
      },
      triggerValidate: false,
      isValid: false,
      requiredCreateKeys: ['name', 'email', 'password', 'role_id', 'system_user', 'login_accepted'],
      requiredEditKeys: [
        'id',
        'name',
        'email',
        'password',
        'role_id',
        'system_user',
        'login_accepted',
      ],
      dialogButtons: [],
    }
  },
  computed: {
    dialogAddButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '保存',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onAddOrEditConfirm,
        },
      ]
    },
    dialogEditButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: 'パスワード変更',
          variant: 'secondary',
          minWidth: 133,
          size: 'md',
          func: this.showChangePasswordDialog,
        },
        {
          title: '更新',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onAddOrEditConfirm,
        },
      ]
    },
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    showEditDialog(rowData, editSuccessCallback) {
      this.formData = { ...rowData }
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            this.callbackFn = editSuccessCallback
            await this.onUpdateConfirm()
          }
        }, 150)
      }
      this.dialogTitle = this.dialogTitles.EDIT.text
      this.dialogButtons = this.dialogEditButtons
      this.$bvModal.show(this.dialogId)
    },

    showAddDialog(createSuccessCallback) {
      this.formData = null
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            const formData = pick(this.formData, this.requiredCreateKeys)
            const { success, data } = await internalMemberManagementService.createItem(formData)
            if (!success) {
              this.displayAlert({
                type: ALERT_TYPE.ERROR,
                messages: data.email ? MESSAGES.ADMIN_MT.ADM05 : MESSAGES.COMMON.MSG15,
              })
              return
            }
            createSuccessCallback && createSuccessCallback()
            this.$bvModal.hide(this.dialogId)
            this.displayAlert({
              type: ALERT_TYPE.SUCCESS,
              messages: MESSAGES.COMMON.MSG01,
            })
          }
        }, 150)
      }
      this.dialogTitle = this.dialogTitles.ADD.text
      this.dialogButtons = this.dialogAddButtons
      this.$bvModal.show(this.dialogId)
    },

    showDeleteDialog(deleteId, deleteSuccessCallback) {
      this.deleteId = deleteId
      this.callbackFn = deleteSuccessCallback
      this.dialogTitle = this.dialogTitles.DELETE.text
      this.$bvModal.show(this.dialogDeleteId)
    },

    async onDeleteConfirm() {
      const result = await internalMemberManagementService.deleteItem(this.deleteId)
      if (result) {
        // delete success
        this.callbackFn && (await this.callbackFn())
        this.displayAlert({
          type: ALERT_TYPE.SUCCESS,
          messages: MESSAGES.COMMON.MSG05,
        })
      }
      this.$bvModal.hide(this.dialogDeleteId)
    },
    async onUpdateConfirm() {
      const formData = pick(this.formData, this.requiredEditKeys)
      const { success, data } = await internalMemberManagementService.editItem(formData)
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: data.email ? MESSAGES.ADMIN_MT.ADM05 : MESSAGES.COMMON.MSG15,
        })
        return
      }
      this.callbackFn && this.callbackFn()
      this.$bvModal.hide(this.dialogId)
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG02,
      })
    },
    showChangePasswordDialog() {
      this.$bvModal.show(this.dialogChangePasswordId)
    },
    async onChangePassword(formData) {
      const { success } = await userService.changePassword(formData, { id: this.formData.id })
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: MESSAGES.COMMON.MSG15,
        })
        return
      }
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG01,
      })
      this.$bvModal.hide(this.dialogChangePasswordId)
    },
  },
}
</script>
