<template>
  <c-modal
    :id="id"
    title="パスワード変更"
    :buttons="dialogButtons"
    :handle-hide-modal="resetFormData"
  >
    <b-form>
      <b-form-group v-if="requireOldPassword" id="input-group-11">
        <b-form-group
          id="input-group-11"
          label="現在のパスワード"
          label-for="input-11"
          class="required"
        >
          <b-input-group>
            <b-form-input
              id="input-11"
              type="password"
              :value="form.old_password"
              :class="status($v.form.old_password)"
              autocomplete="new-password-1"
              :formatter="formatter"
              @change="setFormValue('old_password', $event)"
            />
            <b-input-group-append v-if="!!errorMessages.form.old_password" class="icon-alert">
              <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
            </b-input-group-append>
          </b-input-group>
          <span v-if="!!errorMessages.form.old_password" class="label-error"
            >{{ errorMessages.form.old_password }}
          </span>
        </b-form-group>
      </b-form-group>

      <b-form-group id="input-group-22">
        <b-form-group
          id="input-group-22"
          label="新しいパスワード"
          label-for="input-22"
          class="required"
        >
          <b-input-group>
            <b-form-input
              id="input-22"
              type="password"
              :value="form.password"
              :class="status($v.form.password)"
              autocomplete="new-password-2"
              :formatter="formatter"
              @change="setFormValue('password', $event)"
            />
            <b-input-group-append v-if="!!errorMessages.form.password" class="icon-alert">
              <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
            </b-input-group-append>
          </b-input-group>
          <span v-if="!!errorMessages.form.password" class="label-error"
            >{{ errorMessages.form.password }}
          </span>
        </b-form-group>
      </b-form-group>

      <b-form-group id="input-group-33">
        <b-form-group
          id="input-group-33"
          label="新しいパスワード（確認）"
          label-for="input-33"
          class="required"
        >
          <b-input-group>
            <b-form-input
              id="input-3"
              type="password"
              :value="form.password_confirmation"
              :class="status($v.form.password_confirmation, true)"
              autocomplete="new-password-3"
              :formatter="formatter"
              @change="setFormValue('password_confirmation', $event)"
            />
            <b-input-group-append
              v-if="!!errorMessages.form.password_confirmation"
              class="icon-alert"
            >
              <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
            </b-input-group-append>
          </b-input-group>
          <span v-if="!!errorMessages.form.password_confirmation" class="label-error"
            >{{ errorMessages.form.password_confirmation }}
          </span>
        </b-form-group>
      </b-form-group>
    </b-form>
  </c-modal>
</template>
<script>
import CModal from '@/components/commons/common-modal'
import { helpers, required } from 'vuelidate/lib/validators'
import { MAX_LENGTH } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'

export default {
  name: 'ChangePassword',
  components: { CModal },
  props: {
    id: {
      type: String,
      required: true,
    },
    confirmChangePassword: {
      type: Function,
      default: () => {
        return {}
      },
    },
    requireOldPassword: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialogButtons: [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '確認',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onChangePassword,
        },
      ],
      form: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      errorMessages: {
        form: {
          old_password: '',
          password: '',
          password_confirmation: '',
        },
      },
    }
  },
  validations: {
    form: {
      old_password: {
        required,
      },
      password: {
        required,
        // at least: 8 chars, 1 uppercase, 1 lowercase, 1 number and 1 special char
        passwordPolicy: helpers.regex(
          'passwordPolicy',
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
        ),
      },
      password_confirmation: {
        required,
      },
    },
  },
  computed: {
    defaultFormData() {
      return {
        old_password: '',
        password: '',
        password_confirmation: '',
      }
    },
    errorMessageEmpty() {
      let result = true
      Object.keys(this.errorMessages.form).forEach((key) => {
        if (this.errorMessages.form[key]) {
          result = false
        }
      })
      return result
    },
  },
  mounted() {
    this.form = { ...this.defaultFormData }
    if (!this.requireOldPassword) {
      delete this.form.old_password
      delete this.errorMessages.form.old_password
    }
  },
  methods: {
    status(validation, isConfirm) {
      if (isConfirm) {
        return {
          invalid:
            (validation.$dirty && validation.$invalid) ||
            this.errorMessages.form.password_confirmation !== '',
        }
      }
      return {
        invalid: validation.$dirty && validation.$invalid,
      }
    },
    formatter(e) {
      return String(e).substring(0, MAX_LENGTH.DEFAULT)
    },
    setFormValue(name, value) {
      if (value === undefined) {
        return
      }
      this.form[name] = value
      const validateInstance = this.$v.form[name]
      validateInstance.$touch()
      this.setValidateErrorMessage(validateInstance, name)
    },
    setValidateErrorMessage(instance, name) {
      // check required
      if (!instance.required) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG08
        return
      } else {
        this.errorMessages.form[name] = ''
      }
      this.checkPassword(instance, name)
      this.checkPasswordConfirmation(name)
    },

    checkPassword(instance, name) {
      if (
        (name === 'password' || name === 'old_password') &&
        this.form.password === this.form.old_password &&
        this.requireOldPassword
      ) {
        this.errorMessages.form.password = MESSAGES.ADMIN_MT.ADM10
        return
      }
      if (name === 'password' && this.form.password_confirmation !== '') {
        if (this.form.password !== this.form.password_confirmation) {
          this.errorMessages.form.password_confirmation = MESSAGES.ADMIN_MT.ADM09
        } else {
          this.errorMessages.form.password_confirmation = ''
        }
      }
      if (name === 'password' && !instance.passwordPolicy) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG07
      } else {
        if (!this.errorMessages.form[name]) {
          this.errorMessages.form[name] = ''
        }
      }
    },
    checkPasswordConfirmation(name) {
      if (
        name === 'password_confirmation' &&
        this.form.password !== this.form.password_confirmation
      ) {
        this.errorMessages.form[name] = MESSAGES.ADMIN_MT.ADM09
      } else {
        if (!this.errorMessages.form[name]) {
          this.errorMessages.form[name] = ''
        }
      }
    },
    isValidForm() {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      if (!isValid || !this.errorMessageEmpty) {
        Object.keys(this.errorMessages.form).forEach((key) => {
          this.setValidateErrorMessage(this.$v.form[key], key)
        })
      }
      return this.errorMessageEmpty
    },
    resetFormData() {
      this.$v.form.$reset()
      this.form = { ...this.defaultFormData }
      Object.keys(this.errorMessages.form).forEach((key) => {
        this.errorMessages.form[key] = ''
      })
    },
    onChangePassword() {
      if (!this.isValidForm()) {
        return
      }
      this.confirmChangePassword(this.form)
    },
  },
}
</script>
