<template>
  <div>
    <b-modal
      :id="id"
      centered
      :title="title"
      :size="size"
      :dialog-class="dialogClass"
      @hide="handleHideModal"
    >
      <!--  title -->
      <template v-if="customTitle" #modal-title>
        <slot name="title" />
      </template>
      <!--  body -->
      <template #default>
        <slot />
      </template>
      <!--  footer -->
      <template #modal-footer>
        <div v-if="emptyFooter" />
        <c-button
          v-for="(btn, index) of buttons"
          v-else
          :id="btn.title + index"
          :key="index"
          :size="btn.size"
          :variant="btn.variant"
          :title="btn.title"
          :func="btn.func || hideDialog"
          :min-width="btn.minWidth"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import CButton from '@/components/commons/common-button'

/**
 * Modal component
 */
export default {
  name: 'CommonModal',
  components: { CButton },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    dialogClass: {
      type: String,
      default: '',
    },
    handleHideModal: {
      type: Function,
      default: () => {
        return {}
      },
    },
    emptyFooter: {
      type: Boolean,
      default: false,
    },
    customTitle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hideDialog() {
      this.$bvModal.hide(this.id)
    },
  },
}
</script>
