<template>
  <b-button
    :id="id"
    v-bind="$attrs"
    :variant="variant"
    :size="size"
    :disabled="disabled"
    :style="{ minWidth: `${minWidth}px` }"
    @click="handleClick"
  >
    <slot name="icon" />
    {{ title }}
  </b-button>
</template>

<script>
/**
 * Button component
 */
export default {
  name: 'Button',
  props: {
    func: {
      type: Function,
      default: () => {
        return {}
      },
    },
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'sm',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    minWidth: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      clicked: false, // prevent double click
    }
  },
  methods: {
    handleClick(e) {
      if (this.clicked) {
        return
      }
      this.clicked = true
      e.stopImmediatePropagation()
      this.func()
      setTimeout(() => {
        this.clicked = false
      }, 250)
    },
  },
}
</script>
