<template>
  <div class="CMForm">
    <b-form>
      <b-form-group id="input-group-1" label="名前" label-for="input-1" class="required">
        <b-input-group>
          <b-form-input
            id="input-1"
            :value="form.name"
            type="text"
            :class="status($v.form.name)"
            :formatter="formatter"
            @change="setFormValue('name', $event)"
          />
          <b-input-group-append v-if="!!errorMessages.form.name" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form.name" class="label-error"
          >{{ errorMessages.form.name }}
        </span>
      </b-form-group>

      <b-form-group id="input-group-2" label="メールアドレス" label-for="input-2" class="required">
        <b-input-group>
          <b-form-input
            id="input-2"
            type="text"
            :value="form.email"
            :class="status($v.form.email)"
            :formatter="formatter"
            @change="setFormValue('email', $event)"
          />
          <b-input-group-append v-if="!!errorMessages.form.email" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form.email" class="label-error"
          >{{ errorMessages.form.email }}
        </span>
      </b-form-group>

      <b-form-group
        id="input-group-3"
        label="パスワード"
        label-for="input-password"
        class="required"
      >
        <b-input-group>
          <b-form-input
            id="input-password"
            type="password"
            autocomplete="new-password"
            :value="dummyPassword"
            :formatter="formatter"
            :class="status($v.form.password, true)"
            :disabled="isEditForm"
            @change="setFormValue('password', $event)"
            @click="resetDummyPassword"
          />
          <b-input-group-append v-if="status($v.form.password, true).invalid" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="status($v.form.password, true).invalid" class="label-error"
          >{{ errorMessages.form.password }}
        </span>
      </b-form-group>

      <b-form-group id="select-group-1" label="権限" label-for="select-1">
        <b-form-select
          id="select-1"
          :value="form.role_id"
          class="form-select"
          size="sm"
          :options="options"
          :class="status($v.form.role_id)"
          @change="setFormValue('role_id', $event)"
        />
        <span v-if="!!errorMessages.form.role_id" class="label-error"
          >{{ errorMessages.form.role_id }}
        </span>
      </b-form-group>

      <div class="CMForm__switch form-group">
        <label>顧客としてログイン</label>
        <switcher :value.sync="valueSwitcher" :options="switchOptions" />
      </div>
    </b-form>
  </div>
</template>

<script>
import { email, helpers, required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { MESSAGES } from '@/utils/messages'
import { notFreeMail } from '@/utils/function-utils'
import {
  FORM_MODE,
  MAX_LENGTH,
  PASSWORD_DUMMY,
  ROLE_TYPES,
  SWITCH_LOGIN,
  SYSTEM_ROLES,
} from '@/utils/constants'
import Switcher from '@/components/switcher'

export default {
  name: 'CMForm',
  components: { Switcher },
  props: {
    formData: {
      type: Object,
      default: null,
    },
    triggerValidate: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      errorMessages: {
        form: {
          name: '',
          email: '',
          password: '',
          role_id: '',
        },
      },
      options: [],
      passwordChanged: false,
      dummyPassword: '',
      valueSwitcher: ROLE_TYPES.CUSTOMER,
      formMode: FORM_MODE.ADD,
    }
  },
  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required,
        email,
        // not end with free email
        notFreeMail: notFreeMail,
      },
      password: {
        required,
        // at least: 8 chars, 1 uppercase, 1 lowercase, 1 number and 1 special char
        passwordPolicy: helpers.regex(
          'passwordPolicy',
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
        ),
      },
      role_id: {
        required,
      },
    },
  },
  computed: {
    defaultFormData() {
      return {
        id: '',
        name: '',
        email: '',
        password: '',
        role_id: '',
        system_user: SYSTEM_ROLES.MT_SQUARE,
        login_accepted: SWITCH_LOGIN.DISALLOW,
      }
    },
    ...mapGetters('commonSettings', ['roles']),
    checkPasswordChanged() {
      let errorCount = 0
      Object.keys(this.errorMessages.form).forEach((key) => {
        if (this.errorMessages.form[key]) {
          errorCount += 1
        }
      })
      return !this.passwordChanged && errorCount === 1
    },
    switchOptions() {
      return Object.values(ROLE_TYPES)
    },
    isEditForm() {
      return this.formMode === FORM_MODE.EDIT
    },
  },
  watch: {
    form(newValue) {
      this.$emit('update:formData', newValue)
    },
    triggerValidate(newVal) {
      this.$emit('update:isValid', this.isValidForm())
    },
    valueSwitcher(newValue) {
      if (newValue === this.switchOptions[0]) {
        this.form.login_accepted = SWITCH_LOGIN.ALLOW
      } else {
        this.form.login_accepted = SWITCH_LOGIN.DISALLOW
      }
      this.$emit('update:formData', this.form)
    },
  },
  mounted() {
    this.options = this.roles
      .filter((item) => item.id === ROLE_TYPES.ADMIN)
      .map((role) => {
        return {
          value: role.id,
          text: role.name,
        }
      })
    this.form = { ...this.defaultFormData }
    this.dummyPassword = this.form.password
    if (this.formData) {
      this.form = { ...this.form, ...this.formData }
      this.dummyPassword = PASSWORD_DUMMY
      this.formMode = FORM_MODE.EDIT
    }
    this.valueSwitcher =
      this.form.login_accepted === SWITCH_LOGIN.ALLOW ? ROLE_TYPES.ADMIN : ROLE_TYPES.CUSTOMER
  },
  methods: {
    setValidateErrorMessage(instance, name) {
      // check required
      if (!instance.required) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG08
        return
      } else {
        this.errorMessages.form[name] = ''
      }
      // check password
      this.checkPassWord(instance, name)
      // check email
      this.checkEmail(instance, name)
    },
    setFormValue(name, value) {
      if (value === undefined) {
        return
      }
      if (name === 'password') {
        this.passwordChanged = true
      }
      this.form[name] = value
      const validateInstance = this.$v.form[name]
      validateInstance.$touch()
      this.setValidateErrorMessage(validateInstance, name)
    },
    status(validation, passwordInput = false) {
      if (passwordInput) {
        return {
          invalid:
            validation.$dirty &&
            validation.$invalid &&
            (this.dummyPassword === '' || this.passwordChanged),
        }
      }
      return {
        invalid: validation.$dirty && validation.$invalid,
      }
    },
    isValidForm() {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      if (!isValid) {
        Object.keys(this.errorMessages.form).forEach((key) => {
          this.setValidateErrorMessage(this.$v.form[key], key)
        })
      }
      return isValid || this.checkPasswordChanged
    },
    checkEmail(instance, name) {
      if (name === 'email') {
        if (!instance.email) {
          this.errorMessages.form[name] = MESSAGES.COMMON.MSG09
        }
        if (!instance.notFreeMail) {
          this.errorMessages.form[name] = MESSAGES.COMMON.MSG10
        }
      } else {
        if (!this.errorMessages.form[name]) {
          this.errorMessages.form[name] = ''
        }
      }
    },
    checkPassWord(instance, name) {
      if (name === 'password' && !instance.passwordPolicy) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG07
      } else {
        if (!this.errorMessages.form[name]) {
          this.errorMessages.form[name] = ''
        }
      }
    },
    formatter(e) {
      return String(e).substring(0, MAX_LENGTH.DEFAULT)
    },
    resetDummyPassword() {
      this.dummyPassword = ''
      this.passwordChanged = true
    },
  },
}
</script>
