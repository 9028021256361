<template>
  <div class="MTSwitch" @click="toggleSwitch">
    <input :checked="checkedData" type="checkbox" />
    <div class="slider round"></div>
  </div>
</template>
<script>
export default {
  name: 'Switcher',
  props: {
    value: {
      type: Number,
      required: true,
    },
    // option[0] : checked
    // option[1] : unchecked
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dataValue: this.value,
    }
  },
  computed: {
    checkedData() {
      return this.dataValue === this.options[0]
    },
  },
  watch: {
    checkedData(newVal) {
      if (newVal) {
        this.$emit('update:value', this.options[0])
      } else {
        this.$emit('update:value', this.options[1])
      }
    },
    value(newVal) {
      if (newVal !== this.dataValue) {
        this.dataValue = newVal
      }
    },
  },
  methods: {
    toggleSwitch() {
      if (this.dataValue === this.options[0]) {
        this.dataValue = this.options[1]
      } else {
        this.dataValue = this.options[0]
      }
    },
  },
}
</script>
<style scoped lang="scss">
.MTSwitch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 24px;
  input {
    display: none;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 5px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #556ee6;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #556ee6;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
</style>
