<template>
  <common-button
    title="追加"
    variant="primary"
    size="md"
    class="AddNewButton"
    :func="func"
    :min-width="80"
  >
    <template #icon>
      <img src="@/assets/icons/ic_add_new.svg" alt="add-icon" />
    </template>
  </common-button>
</template>
<script>
import CommonButton from '@/components/commons/common-button'
export default {
  name: 'AddNewButton',
  components: { CommonButton },
  props: {
    func: {
      type: Function,
      default: () => {
        return {}
      },
    },
  },
}
</script>
<style lang="scss">
.AddNewButton {
  height: 37px;
  img {
    width: 16px;
    height: 16px;
    margin-right: 2px;
    margin-bottom: 2px;
  }
}
</style>
