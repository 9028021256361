import { START_YEAR } from '@/utils/constants'

export const stringToDateJapan = (strDate) => {
  const d = new Date(Date.parse(strDate))
  const lang = 'en'
  const year = d.toLocaleString(lang, { year: 'numeric' })
  const month = d.toLocaleString(lang, { month: 'numeric' })
  const day = d.toLocaleString(lang, { day: 'numeric' })
  return `${year}年${month}月${day}日`
}

export const stringToDateMonthJapan = (strDate) => {
  const d = new Date(Date.parse(strDate))
  const lang = 'en'
  const year = d.toLocaleString(lang, { year: 'numeric' })
  const month = d.toLocaleString(lang, { month: 'numeric' })
  return `${year}年${month}月`
}

export const getYearsOptions = (yearsFromCurrentYear = 0) => {
  return Array.from(
    { length: new Date().getFullYear() + yearsFromCurrentYear - START_YEAR + 1 },
    (value, index) => START_YEAR + index
  )
}
