<template>
  <div class="SearchInput">
    <div class="SearchInput__text">
      <i class="bx bx-search-alt font-size-18" aria-hidden="true" />
      <b-form-input
        v-model="searchValue"
        type="search"
        placeholder="検索 ..."
        @keyup.enter="emitNewValue"
      />
    </div>
  </div>
</template>
<script>
import { handleChangeInput } from '@/utils/function-utils'
import { mapActions } from 'vuex'

export default {
  name: 'SearchInput',
  props: {
    value: {
      type: String,
      default: '',
      timer: null,
    },
  },
  data: function () {
    return {
      searchValue: this.value,
    }
  },
  watch: {
    searchValue() {
      this.timer = handleChangeInput(this.timer, this.emitNewValue, 500)
    },
  },
  mounted() {
    this.disableLoading()
  },
  beforeDestroy() {
    this.enableLoading()
  },
  methods: {
    ...mapActions('loading', ['disableLoading', 'enableLoading']),
    emitNewValue() {
      this.$emit('update:value', this.searchValue)
    },
  },
}
</script>
<style lang="scss">
.SearchInput {
  display: flex;
  &__text {
    position: relative;
    i {
      position: absolute;
      top: 10px;
      left: 14px;
    }
    input {
      min-width: 360px;
      height: 37px;
      padding-left: 39px;
      border-radius: 10px;
    }
    .search-box .form-control {
      border-radius: 10px;
      padding-left: 40px;
    }
  }
  &__button {
    margin-left: 10px;
    button {
      height: 37px;
      border-radius: 10px;
    }
  }
}
</style>
