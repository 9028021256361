<template>
  <span class="SortIcon">
    <svg width="5" height="14" viewBox="0 0 5 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.49984 0.0833349L4.6665 2.25H3.0415L3.0415 10.375H1.95817L1.95817 2.25H0.333171L2.49984 0.0833349Z"
        fill="#D8DADB"
      />
    </svg>
    <svg width="5" height="14" viewBox="0 0 5 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.49984 0.0833349L4.6665 2.25H3.0415L3.0415 10.375H1.95817L1.95817 2.25H0.333171L2.49984 0.0833349Z"
        fill="#D8DADB"
      />
    </svg>
  </span>
  <!--  #343A40-->
</template>
<script>
export default {
  name: 'SortIcon',
}
</script>
<style lang="scss">
.SortIcon {
  white-space: nowrap;
  svg {
    margin-left: 3px;
  }
  svg:last-of-type {
    transform: rotate(180deg);
  }
}
</style>
