<template>
  <c-modal :id="id" :buttons="dialogButtons">
    <div class="text-center font-size-16">削除してもよろしいですか？</div>
  </c-modal>
</template>
<script>
import CModal from '@/components/commons/common-modal'

export default {
  name: 'DeleteConfirmation',
  components: { CModal },
  props: {
    id: {
      type: String,
      required: true,
    },
    confirmDeleteFunc: {
      type: Function,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      dialogButtons: [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '削除',
          variant: 'danger',
          minWidth: 118,
          size: 'md',
          func: this.confirmDeleteFunc,
        },
      ],
    }
  },
}
</script>
