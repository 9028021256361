<template>
  <div class="PageLayout">
    <page-header :title="pageTitles" :items="breadCrumbItems" />
    <div v-if="topLayout" class="PageLayout__top">
      <slot name="top-content" />
    </div>
    <div v-if="tableLayout" class="PageLayout__table">
      <slot name="table-content" />
    </div>
    <div v-if="tableLayoutOther" class="PageLayout__table-second">
      <slot name="table-content-other" />
    </div>
    <div class="PageLayout__other">
      <slot name="other-content" />
    </div>
  </div>
</template>
<script>
import PageHeader from '@/layouts/vertical/page-header'

export default {
  name: 'PageTableLayout',
  components: { PageHeader },
  props: {
    pageTitles: {
      type: String,
      required: true,
    },
    breadCrumbItems: {
      type: Array,
      default: () => [],
    },
    topLayout: {
      type: Boolean,
      default: false,
    },
    tableLayout: {
      type: Boolean,
      default: true,
    },
    tableLayoutOther: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss">
.PageLayout {
  &__table {
    background-color: white;
    padding: 24px 0 20px 24px;
  }
  &__top {
    background-color: white;
    margin-bottom: 24px;
  }
  &__table-second {
    background-color: white;
    padding: 24px 0 20px 24px;
    margin-top: 24px;
  }
}
</style>
