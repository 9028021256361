<template>
  <div class="row Paging">
    <label class="d-inline-flex align-items-center float-start">
      表示
      <b-form-select
        v-model="dataPerPage"
        class="form-select form-select-sm"
        size="sm"
        :options="optionsPaging"
      />
      件
    </label>
    <div class="col">
      <div class="paging_simple_numbers float-end">
        <ul class="pagination pagination-rounded mb-0">
          <!-- pagination -->
          <b-pagination v-model="dataCurrentPage" :total-rows="total" :per-page="perPage" />
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TablePaging',
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    optionsPaging: {
      type: Array,
      default: () => {
        return [10, 50, 100]
      },
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dataCurrentPage: this.currentPage,
      dataPerPage: this.perPage,
    }
  },
  watch: {
    dataCurrentPage(val) {
      this.$emit('update:currentPage', val)
    },
    dataPerPage(val) {
      this.$emit('update:perPage', val)
    },
  },
}
</script>
<style lang="scss">
.Paging {
  label {
    max-width: 170px;
  }
  select {
    width: 76px;
    margin: 0 10px;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
